import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_ALM_SERVICE}/alm-service`);

const almService = {
    uploadResults(data, then, error) {
        service.postRequest(1, 'upload-results', data, then, error);
    },
    getFolders(data, then, error) {
        service.postRequest(1, 'folders', data, then, error);
    },
    sync(projectHash, then, error) {
        service.patchRequest(1, 'sync', {domain: projectHash}, then,
            error,
        );
    },
}

export default almService;